import { combineEpics } from 'redux-observable';
import CoderEpic from './CoderApis/coder.epic';
import AppointmentEpic from './Appointment/apt.epic';

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  CoderEpic.loginApi,
  CoderEpic.coderListApi,
  CoderEpic.getCoderApi,
  CoderEpic.icdSearchApi,
  CoderEpic.cptSearchApi,
  CoderEpic.logInfoSaveApi,
  CoderEpic.icdSaveApi,
  CoderEpic.cptSaveApi,
  CoderEpic.coderSaveApi,
  CoderEpic.loginInfoApi,
  CoderEpic.fileUploadApi,
  CoderEpic.downloadFileApi,
  CoderEpic.logListApi,
  CoderEpic.bulkUpdateApi,
  CoderEpic.rowUpdateApi,
  CoderEpic.icdKeywordSearchApi,
  CoderEpic.cptDeleteApi,
  CoderEpic.icdChildApi,
  CoderEpic.paHistoryApi,
  CoderEpic.searchPayorApi,
  CoderEpic.patientSaveApi,
  CoderEpic.policyHolderSaveApi,
  CoderEpic.policySaveApi,
  AppointmentEpic.searchPatientApi,
  AppointmentEpic.cacheApi,
  AppointmentEpic.AppointmentSaveApi,
  AppointmentEpic.payerSaveApi,
  AppointmentEpic.exportData,
);
export default rootEpic;
