import {
  BULK_UPDATE_RES,
  CPT_DELETE_RES,
  CPT_SAVE_RES,
  CPT_SEARCH_RES,
  DOWNLOAD_RES,
  FILE_UPLOAD_RES,
  GET_CODER_RES,
  ICD_CHILD_RES,
  ICD_KEYWORD_SEARCH_RES,
  ICD_SAVE_RES,
  ICD_SEARCH_RES,
  LOGIN_INFO_RES,
  LOGIN_RES,
  LOGLIST_RES,
  LOG_INFO_SAVE_RES,
  PATIENT_SAVE_RES,
  PA_HISTORY_RES,
  POLICYHOLDER_SAVE_RES,
  POLICY_SAVE_RES,
  CODER_LIST_RES,
  CODER_SAVE_RES,
  SEARCH_PAYOR_RES,
  SET_FILTER,
} from './coder.types';
/**
 * @fileOverview Manages the response of action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  loginResponse: null,
  tableData: null,
  coderInfo: null,
  filterForm: {
    clinic: null,
    provider: null,
    status: null,
    result: null,
    startDate: null,
    endDate: null,
    owner: null,
  },
  icdSearchResponse: null,
  cptSearchResponse: null,
  logInfoResponse: null,
  icdResponse: null,
  cptSaveResponse: null,
  coderSaveResponse: null,
  cache: null,
  fileUploadResponse: null,
  downloadResponse: null,
  logList: null,
  bulkUpdateResponse: null,
  icdKeywordList: null,
  cptDeleteResponse: null,
  paHistory: null,
  payorlist: null,
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const regReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, ...{ loginResponse: action.payload } };
    }
    case CODER_LIST_RES: {
      return { ...state, ...{ tableData: action.payload } };
    }
    case GET_CODER_RES: {
      return { ...state, ...{ coderInfo: action.payload } };
    }
    case SET_FILTER: {
      return { ...state, ...{ filterForm: action.payload } };
    }
    case ICD_SEARCH_RES: {
      return { ...state, ...{ icdSearchResponse: action.payload } };
    }
    case ICD_SAVE_RES: {
      return { ...state, ...{ icdResponse: action.payload } };
    }
    case CPT_SEARCH_RES: {
      return { ...state, ...{ cptSearchResponse: action.payload } };
    }
    case LOG_INFO_SAVE_RES: {
      return { ...state, ...{ logInfoResponse: action.payload } };
    }
    case CPT_SAVE_RES: {
      return { ...state, ...{ cptSaveResponse: action.payload } };
    }
    case CODER_SAVE_RES: {
      return { ...state, ...{ coderSaveResponse: action.payload } };
    }
    case LOGIN_INFO_RES: {
      return { ...state, ...{ cache: action.payload } };
    }
    case FILE_UPLOAD_RES: {
      return { ...state, ...{ fileUploadResponse: action.payload } };
    }
    case DOWNLOAD_RES: {
      return { ...state, ...{ downloadResponse: action.payload } };
    }
    case LOGLIST_RES: {
      return { ...state, ...{ logList: action.payload } };
    }
    case BULK_UPDATE_RES: {
      return { ...state, ...{ bulkUpdateResponse: action.payload } };
    }
    case ICD_KEYWORD_SEARCH_RES: {
      return { ...state, ...{ icdKeywordList: action.payload } };
    }
    case CPT_DELETE_RES: {
      return { ...state, ...{ cptDeleteResponse: action.payload } };
    }
    case ICD_CHILD_RES: {
      return { ...state, ...{ icdChildResponse: action.payload } };
    }
    case PA_HISTORY_RES: {
      return { ...state, ...{ paHistory: action.payload } };
    }
    case SEARCH_PAYOR_RES: {
      return { ...state, ...{ payorlist: action.payload } };
    }
    case PATIENT_SAVE_RES: {
      return { ...state, ...{ patientSaveResponse: action.payload } };
    }
    case POLICYHOLDER_SAVE_RES: {
      return { ...state, ...{ HolderResponse: action.payload } };
    }
    case POLICY_SAVE_RES: {
      return { ...state, ...{ policySaveResponse: action.payload } };
    }
    default:
      return state;
  }
};
export default regReducer;
