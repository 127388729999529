import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './Containers/NewLogin';
import { Toaster } from 'react-hot-toast';
import './GlobalStyles.css';
// import EditContainer from './Containers/EditContainer';
// import DataTableContainer from './Containers/DataTableContainer';
import AppLogout from './Utils/inactive';
import SimpleBackdrop from './Components/Backdrop';
const DataTableContainer = lazy(() =>
  import('./Containers/DataTableContainer')
);
// const EditContainer = lazy(() => import('./Containers/EditContainer'));
const EditContainer = lazy(() => import('./Containers/EditContainer'));

function App() {
  const [rowData, setRowData] = useState(
    JSON.parse(sessionStorage.getItem('rowData')) || null
  );

  const token = localStorage.getItem('accessToken');
  useEffect(() => {
    sessionStorage.setItem('rowData', JSON.stringify(rowData));
  }, [rowData]);

  return (
    <div>
      <AppLogout>
        <Toaster position="top-right" reverseOrder={false} />
        <BrowserRouter>
          <Switch>
            <Route
              path="/home"
              name="home"
              render={(props) =>
                token ? (
                  <Suspense fallback={<SimpleBackdrop />}>
                    <DataTableContainer {...props} passRowData={setRowData} />
                  </Suspense>
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              path="/edit"
              name="edit"
              render={(props) =>
                token ? (
                  // <Suspense fallback={<div>Loading...</div>}>
                  <Suspense fallback={<SimpleBackdrop />}>
                    <EditContainer
                      {...props}
                      setRowData={setRowData}
                      rowData={rowData}
                    />
                  </Suspense>
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route path={'/login'} name={'login'} component={Login} />
            <Redirect from="/" to="/login" />
          </Switch>
        </BrowserRouter>
      </AppLogout>
    </div>
  );
}

export default App;
