import { combineReducers } from "redux";
import regReducer from "./CoderApis/coder.reducer";
import aptReducer from "./Appointment/apt.reducer";
/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 */

const rootReducer = combineReducers({
  coder: regReducer,
  Appointment: aptReducer,
});

export default rootReducer;
