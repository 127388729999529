import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store from './Redux/store';
// import App from './App';
import ContextProvider from 'context/contextProvider';
import Context from './context/AppContextProvider';
import App from './App';
import { configureLibrary } from '../../../libs/Redux/CoderApis/main.epic';
import environment from './environments/environment';
import { configureApptEpic } from '../../../libs/Redux/Appointment/apt.epic';

const theme = createTheme({
  typography: {
    fontFamily: `Inter, sans-serif`,
  },
  palette: {
    primary: {
      main: '#139696', // Teal color which matches the companies theme
    },
    secondary: {
      main: '#697386', // Grey colour matches sail-gray 300
    },
  },
});

const { baseUrl } = environment;
const { ClinicBaseUrl } = environment;
configureLibrary({
  url: baseUrl,
  clinicUrl: ClinicBaseUrl,
});

configureApptEpic({
  url: baseUrl,
  clinicUrl: ClinicBaseUrl,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Context components={[ContextProvider]}>
        <App />
      </Context>
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);
